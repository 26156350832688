.app{
    height: 100vh;

    .sections{
        overflow-y: scroll;
        scrollbar-width: none;
        &::-webkit-scrollbar{
            display:none;
        }
        width:100vw;
        height: 100vh;
        background-color:lightsalmon;
        position: relative;
        top:70px;
        scroll-behavior: smooth;
        scroll-snap-type: y mandatory;        
        > *{
            width: 100vw;
            height: 100vh;
            scroll-snap-align: start;
        }
    }
}
//Remove scroll bar but keep functionality
.app::-webkit-scrollbar { width: 0 !important }
.app { overflow: -moz-scrollbars-none; }
.app { -ms-overflow-style: none; }
