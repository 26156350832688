@import "../../global.scss";

.projects{
    background-color: white;
    display: flex;
    flex-direction: column;
    text-align-last: center;

    @include mobile{
        padding-left: 0;
        padding-right: 0;
        align-items: center;
    }
    

    .projectName{
        font-size: 32.5px;
        padding-top: 20px;
        @include mobile{
            padding-left: 5%;
            padding-right: 5%;
            font-size: 30px;

        }
    }

    .CScomponents{
        font-size: 22.5px;
        font-style: italic;
        font-weight: 100;
        @include mobile{
            padding-left: 5%;
            padding-right: 5%;
            font-size: 17.5px;
            padding-top: 0;
            text-align-last: center;
        }

    }

    .desc{
        padding-top: 5px;
        font-size: 20px;
        padding-left: 15%;
        padding-right: 15%;
        text-align: center;
      //  padding-left: 12.5%;
        font-weight: 100;
        @include mobile{
            align-items: center;
             font-size: 17.5px;
             padding-right: 10%;
             padding-left: 5%;
             padding-right: 5%;
         }
    }

}