@import "../../global.scss";
.portfolio {
  background-color: white;
  display: flex;
  flex-direction: column;
  text-align: center;
  text-align-last: center;

  @include mobile {
    padding-left: 5%;
    padding-right: 5%;
    align-items: center;
  }
}
