@import "../../global.scss";
.extracurics {
  background-color: white;
  display: flex;
  flex-direction: column;
  //  padding-left: 10%;
  //   padding-right: 10%;
  text-align: center;
  text-align-last: center;

  @include mobile {
    padding-left: 0;
    padding-right: 0;
    align-items: center;
  }

  .organization {
    font-size: 32.5px;
    //  padding-left: 10%;
    padding-top: 20px;
    @include mobile {
      padding-top: 10px;
      padding-left: 0%;
      // padding-right: 5%;
      font-size: 22.5px;
      text-align-last: center;
    }
  }

  .location {
    font-size: 22.5px;
    font-style: italic;
    font-weight: 100;
    // padding-left: 12.5%;
    @include mobile {
      padding-left: 5%;
      padding-right: 5%;
      padding-bottom: 10px;
      font-size: 17.5px;
      text-align-last: center;
    }
  }
}
