@import "../../global.scss";
.intro{
    background-color: $mainBackgroundColor;
    display:flex;
    padding-top: 60px;
    text-align: center;
    text-align-last:center ;
    @include mobile{
        flex-direction: column;
        align-items: center;
    }
    .itemContainer{
        color: $activeColor;
        display: none;
        @include mobile{
            flex-direction: row;
            display: contents;
            padding: 10px;
        }
    }
    .col{
        display: flex;
        flex-direction: column;
        text-align: center;
        align-items: center;
    }
.iconContainer{
    width: 100%;
    padding-top: 10px;
    display: flex;
    text-align: center;
    font-size: 15px;
    justify-content: space-between;
    max-width: 400px;
    margin: 0 15vw;
    padding-top: 25px;
    a{
        color: $activeColor;
        display: none;
        @include mobile{
            flex-direction: row;
            display: contents;
            padding: 10px;
        }
    }
}
    .left{
        flex:0.5;
        overflow: hidden;
        .imageContainer{

            display: flex;
            align-items: center;
            justify-content: center; 
            float: right;
            align-items: flex-end;
            @include mobile{
                align-items: flex-start;
            }

            img{
                width: 90%;
                @include mobile{
                    height:40%;
                }
            }
        }
    }

    .right{
        overflow: hidden;
        flex: 0.5;
        position: relative;
        .wrapper{
            width: 100%;
            height: 100%;
            align-items: center;
            display:flex;
            flex-direction: column;
            justify-content: center;

            @include mobile{
                padding-left: 0;
                align-items: center;
            }
            h1{
                font-size: 60px;
                margin: 10px 0;

                @include mobile{
                    font-size: 40px;
                }
            }

            h2{
                font-size: 50px;
                @include mobile{
                    font-size: 25px;
                }
            }

            h3{
                font-size: 30px;
                color:$activeColor;
                @include mobile{
                    font-size: 20px;
                }
            }

            .ityped-cursor{
                animation: blink 1s infinite;
            }

            @keyframes blink {
                50%{
                    opacity: 1;
                }

                100%{
                    opacity: 0;
                }
            }
        }
        a{
            position: absolute;
            bottom:10px;
            left:45%;
            
           .arrowDown{
               margin-bottom: 45px;
               width: 100px;
               height: 100px;
               animation: arrowBlink 2s infinite;
               @include mobile{
                   display: none;
               }
           }
           @keyframes arrowBlink {
               100%{
                   opacity: 0;
               }
           }
        }
    }
}