@import "../../global.scss";

.education {
  background-color: $mainBackgroundColor;
  display: flex;
  flex-direction: column;
  padding-left: 10%;
  padding-right: 10%;

  @include mobile {
    padding-left: 2%;
    padding-right: 2%;
  }

  .info {
    font-style: italic;
    font-weight: 100;
    width: auto;
    margin-top: 10px;
    @include mobile {
      padding-left: 5%;
      padding-right: 5%;
      padding-bottom: 10px;
      text-align: center;
      text-align-last: center;
    }
  }

  .school {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .school_logo {
    @include mobile {
      width: 60%;
    }
  }

}
