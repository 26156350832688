@import "../../global.scss";

.navigator{
    width:100%;
    height:65px;
    background-color: $activeColor;
    color:$nonActiveColor;
    overflow: hidden;
    position:fixed;
      
    top:0;
    z-index: 3;
    transition: all 1s ease;
    a{
        text-decoration: none;
        color:white;
    }
    .wrapper{
        font-family:Georgia, 'Times New Roman', Times, serif;
        padding: 10px 30px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .left{
            display:flex;
            align-items: center;
            text-decoration: none;

          

            .logo{
                font-size: 40px;
                font-weight: 750;
                text-decoration: none;
                margin-right: 40px;
                color:$nonActiveColor;
            }
            .itemContainer{
                align-items: center;
                display: flex;
                margin-right: 30px;
                @include mobile{
                    display: none;
                }
            }
            .icon{
                font-size: 35px;
                margin-right: 5px;
            }
            span{
                font-size: 25px;
                font-weight: 500;
            }

        }

        .right{
            .hamburger{
                width: 32px;
                height: 25px;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                cursor: pointer;
                span{
                    width: 100%;
                    height: 3px;
                    background-color: $nonActiveColor;
                    transform-origin: left;
                    transition: all 1s ease;
                }
            }
        }
    }
    &.active{
        border-bottom:3px;
        border-bottom-style: solid;
        border-bottom-color: $activeColor;
        a{
            color:$activeColor;
        }
        background-color: $nonActiveColor;
        color: $activeColor;
        .left{
            .logo{
                color:$activeColor;
            }
        }
        .hamburger{
            overflow-y: scroll;
            scrollbar-width: none;
            &::-webkit-scrollbar{
                display:none;
            }
            span{
                &:first-child{
                    background-color: $activeColor;
                    transform: rotate(45deg);
                }
                &:nth-child(2){
                    opacity: 0;
                }
                &:last-child{
                    background-color: $activeColor;
                    transform: rotate(-45deg);
                }
            }
        }
    }
}