@import "../../global.scss";

.menu{
    color:white;
    width:400px;
    height:100vh;
    background-color:$activeColor;
    position: fixed;
    top: 0;
    right: -400px;
    z-index: 2;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition: all 0.5s ease;

    &.active{
        color:$activeColor;
        right: 0;
    }

    ul{
        margin:0;
        padding:0;
        list-style: none;
        font-size: 30px;
        font-weight: 300;;
        color:white;
        width: 60%;
        li{
            margin-bottom: 25px;
            a{
                font-size: inherit;
                color:inherit;
                text-decoration: none;
            }
            &:hover{
                font-weight: bold;
            }
        }
    }
}