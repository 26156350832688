$mainColor: #636CFD;
$activeColor: #636CFD;
$nonActiveColor:white;
$mainBackgroundColor: white;
$width:1200px;
@mixin mobile {
    @media (max-width: #{$width}){
        @content
    }
}

h1.header {
    font-size: 45px;
    align-items: center;
    display: flex;
    flex-direction: column;
    font-family: "Lobster";
    @include mobile {
      font-size: 35px;
    }
  }