@import "../../../global.scss";

.slider {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 500px;
  overflow: hidden;
}

.slide {
  user-select: none;
  border: none;
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  justify-content: center;
  transition: opacity 1s, visibility 1s; /* Smooth transition for opacity and visibility */
  opacity: 1;
  visibility: visible;
}
.slide-hidden {
  opacity: 0;
  visibility: hidden;
  position: absolute; /* Position absolutely to stack slides */
}

.arrow {
  position: absolute;
  width: 2rem;
  height: 2rem;
  color: white;
  filter: drop-shadow(0px 0px 5px #555);
  @include mobile {
    display: none;
  }
}
.arrow:hover {
  cursor: pointer;
}
.arrow-left {
  left: 1rem;
  margin-left: 5%;
}
.arrow-right {
  right: 1rem;
  margin-right: 5%;
}

.indicators {
  display: flex;
  position: absolute;
  bottom: 1rem;
}
.indicator {
  background-color: $activeColor;
  height: 0.5rem;
  width: 0.5rem;
  border-radius: 100%;
  border: none;
  outline: none;
  box-shadow: 0px 0px 1px #555;
  margin: 0 0.2rem;
  cursor: pointer;
}
.indicator-inactive {
  background-color: white;
}

.company_info {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 2%;
}
.info {
  font-weight: 100;
  margin-top: 3%;
  @include mobile {
    margin-top: 10%;
  }
}

.desc {
  font-weight: 100;
  margin-left: 10%;
  margin-right: 10%;
  @include mobile {
    display: none;
  }
}

.company_logo {
  object-fit: cover;
}

.position {
  font-weight: 100;
}
